import Layout from "../../components/layout";
import Helmet from "react-helmet";
import Header from "../../components/header";
import React from "react";
import {graphql} from "gatsby";

export default class WorkshopPage extends React.Component {

    constructor() {
        super();
        this.state = {};
    }

    state = {
        hover1: false,
        hover2: false,
        hover3: false,
        hover4: false,
    };

    hover = property => {
        this.setState({[property]: this.state[property] = !this.state[property]});
    };

    hoverNo = property => {
        this.setState({[property]: this.state[property] = true});
    };

    hoverOff = property => {
        this.setState({[property]: this.state[property] = false});
    };

    render() {
        let siteType = process.env.GATSBY_API_URL;
        let {hover1, hover2, hover3, hover4} = this.state;
        let workshopeBlog = this.props.data.allStrapiBlogs.edges.find(blog => blog.node.id === "Blogs_21");
        let offersData = this.props.data.allStrapiOfferPages.edges;
        return (
            <Layout page="workshop">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Chatbot Workshop | Paixon</title>
                    <meta name="description"
                          content="Ihr Unternehmen interessiert sich für Chat- und Voicebots? Mit dem Paixon Chatbot
                          Workshop finden Sie den perfekten Einstieg in die Thematik."/>
                </Helmet>
                <Header className={`offer/media`}
                        slogan={this.props.data.allStrapiPageWorkshops.edges[0].node.slogan}
                        blogphoto={{backgroundImage: `url(${this.props.data.allStrapiPageWorkshops.edges[0].node.photo.publicURL})`}}
                        offersData={offersData}/>
                <main className="container workshop pt">
                    <h1>Der Paixon Chatbot Workshop</h1>
                    <p>Egal ob sie bereits eine klare Idee haben oder noch ganz am Anfang der Chatbot-Journey stehen
                        - wir holen sie dort ab, wo wir den grössten Mehrwert generieren. Jeden Workshop passen wir
                        individuell Ihren Ansprüchen an. Während dem interaktiven Workshop gehen wir zielorientiert und
                        unternehmerisch vor. Spass und Teambuilding kommen dabei nicht zu kurz.
                    </p>

                    <div className="workshop-question">
                        <h2>Für wen eignet sich der Workshop?</h2>
                        <p>Der Workshop ist für folgende Unternehmen und Organisationen geeignet:</p>
                        <div className="workshop-company">
                            <div className="workshop-company-imag"
                                 style={{backgroundImage: `url('https://admin.paixon.ch/uploads/0240c3fe606f4306b291588fc59580e8.svg')`}}></div>
                            <div className="workshop-company-text">
                                <h3>
                                    Die Strategischen
                                </h3>
                                <p>
                                    Sie wollen einen Chat- oder Voicebot entwickeln oder einen Bestehenden erweitern.
                                </p>
                            </div>
                        </div>
                        <div className="workshop-company">
                            <div className="workshop-company-imag"
                                 style={{backgroundImage: `url('https://admin.paixon.ch/uploads/6dac5fd7f4fe4372b61c9fcc85195b4f.svg')`}}></div>
                            <div className="workshop-company-text">
                                <h3>
                                    Die Innovativen
                                </h3>
                                <p>
                                    Sie wollen die Digitalisierung vorantreiben und sind auf der Suche nach einem
                                    innovativen und vielversprechenden Ansatz.
                                </p>
                            </div>
                        </div>
                        <div className="workshop-company">
                            <div className="workshop-company-imag"
                                 style={{backgroundImage: `url('https://admin.paixon.ch/uploads/35f0d43514c34817816f2040bb4bf834.svg')`}}></div>
                            <div className="workshop-company-text">
                                <h3>
                                    Die Neugierigen
                                </h3>
                                <p>
                                    Sie wollen einen Blick in die Zukunft werfen und neue Möglichkeiten kennenlernen.
                                </p>
                            </div>
                        </div>
                        <div className="workshop-company">
                            <div className="workshop-company-imag"
                                 style={{backgroundImage: `url('https://admin.paixon.ch/uploads/478cff33a05548d8979f80655b46f844.svg')`}}></div>
                            <div className="workshop-company-text">
                                <h3>
                                    Die Experten
                                </h3>
                                <p>
                                    Sie entwickeln einen digitalen Assistenten und suchen den Austausch mit einem
                                    Expertenteam.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="workshop-question">
                        <h2>Was sind die Inhalte des Chatbot-Workshops?</h2>

                        <div className="workshop-steps">
                            <div className="workshop-steps-img"></div>
                            <div className="workshop-steps-text">
                                <div className="workshop-steps-text-step">
                                    <h3>Chat- und Voicebots kennenlernen</h3>
                                    <p>Wir erklären was Chatbot und Sprachassistenten sind oder zeigen wie Chatbots in
                                        Ihrer
                                        Branche eingesetzt werden.</p>
                                    <p className="workshop-steps-text-more"
                                       onClick={() => this.hover("hover1")}
                                       onMouseEnter={() => this.hoverNo("hover1")}
                                       onMouseLeave={() => this.hoverOff("hover1")}>Mehr Informationen...</p>
                                    {this.state.hover1 && (
                                        <div className="workshop-steps-img-hover">
                                            <p className="workshop-steps-img-hover-title">Wir beantworten Ihnen Ihre
                                                Fragen zu Chat- und Voicebots:</p>
                                            <p>Was sind Chatbots? Was ist Künstliche Intelligenz und wie profitieren
                                                virtuelle Assistenten davon? Wie lernen KI-Bots? Wo werden Voicebots
                                                eingesetzt? Wie geht man bei der Entwicklung eines Chatbots vor? Wie
                                                kann man den Erfolg von digitalen Assistenten messen? Welche Typen
                                                von Chatbots gibt es? Wie gestaltet man eine
                                                Mensch-Maschinen-Konversation? </p>
                                        </div>
                                    )
                                    }
                                </div>
                                <div className="workshop-steps-text-step">
                                    <h3>Anwendungsfälle ermitteln</h3>
                                    <p>Zusammen ermitteln wir die Herausforderungen des Unternehmens und bestimmen, wo
                                        der
                                        Einsatz
                                        von Chat- und Voicebots vielversprechend ist.</p>
                                    <p className="workshop-steps-text-more"
                                       onClick={() => this.hover("hover2")}
                                       onMouseEnter={() => this.hoverNo("hover2")}
                                       onMouseLeave={() => this.hoverOff("hover2")}>Mehr Informationen...</p>
                                    {this.state.hover2 && (
                                        <div className="workshop-steps-img-hover">
                                            <p className="workshop-steps-img-hover-title">Gemeinsam ermitteln wir…</p>
                                            <p>Herausforderungen, Chancen und Ziele, Aktuelle Customer Journeys,
                                                Personas der Zielgruppen, Prozesse und Abläufe, Image und Werte der
                                                Kampagne, des Unternehmens oder eines Produkts, Vor- und Nachteile von
                                                Anwendungsfällen
                                            </p>
                                        </div>
                                    )
                                    }
                                </div>
                                <div className="workshop-steps-text-step">
                                    <h3>Anforderungsanalyse und Lösungsdesign erarbeiten</h3>
                                    <p>Wir definieren die Ziele des Chatbots, arbeiten die Anforderungen aus, entwickeln
                                        in
                                        kreativen Prozessen die Chatbot-Persönlichkeit und erste Dialoge</p>
                                    <p className="workshop-steps-text-more"
                                       onClick={() => this.hover("hover3")}
                                       onMouseEnter={() => this.hoverNo("hover3")}
                                       onMouseLeave={() => this.hoverOff("hover3")}>Mehr Informationen...</p>
                                    {this.state.hover3 && (
                                        <div className="workshop-steps-img-hover">
                                            <p className="workshop-steps-img-hover-title">Wir definieren zusammen...
                                            </p>
                                            <p>Dialogphasen und -sprache, Beispieldialoge, Visuelle Design-Elemente
                                                (wie der Avatar, Dialogkomponenten oder die Webseiten-Integration),
                                                Zielsetzungen und Key Performance Indicators (KPIs), Schnittstellen,
                                                Artificial Intelligence bzw. NLP-Trainings-Daten</p>
                                        </div>
                                    )
                                    }
                                </div>
                                <div className="workshop-steps-text-step">
                                    <h3>Über den Tellerrand blicken</h3>
                                    <p>Wir unterstützen Sie bei der Digitalisierungsstrategie, durchleuchten Ihre
                                        IT-Umgebung und sorgen für eine reibungslose Integration des digitalen
                                        Assistenten. Auf Wunsch stehen wir Ihnen auch beim Bekanntmachen des Chatbots
                                        zur Seite und planen mit Ihnen das Marketing und die Kommunikation.</p>
                                    <p className="workshop-steps-text-more"
                                       onClick={() => this.hover("hover4")}
                                       onMouseEnter={() => this.hoverNo("hover4")}
                                       onMouseLeave={() => this.hoverOff("hover4")}>Mehr Informationen...</p>
                                    {this.state.hover4 && (
                                        <div className="workshop-steps-img-hover">
                                            <p className="workshop-steps-img-hover-title"> Ganz individuell unterstützen
                                                wir Sie bei...
                                            </p>
                                            <p>Projektplanung und -abwicklung, Technische Integration und Deployment,
                                                Datenmodellierung und Softwareentwicklung, Zusammensetzung des
                                                Projektteams, Marketing- und Sales-Strategie</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="workshop-question">
                        <h2>Was beinhaltet das Workshop-Package?</h2>
                        <p>Das Workshop-Package besteht aus vier Bestandteilen</p>
                        <div className="workshop-documentation">
                            <div className="workshop-documentation-item">
                                <div className="workshop-documentation-item-text">
                                    <h3 className="workshop-documentation-item-text-strong">Das Briefing</h3>
                                    <p>Gemeinsam diskutieren wir Ihre Bedürfnisse, bestimmen die Teilnehmenden und legen
                                        einen Workshop-Termin fest.
                                    </p>
                                </div>
                            </div>
                            <div className="workshop-documentation-item">
                                <div className="workshop-documentation-item-text">
                                    <h3 className="workshop-documentation-item-text-strong">Die Vorbereitung</h3>
                                    <p>Basierend auf Ihren Inputs bereiten wir einen individuellen Workshop vor.
                                    </p>
                                </div>
                            </div>
                            <div className="workshop-documentation-item">
                                <div className="workshop-documentation-item-text">
                                    <h3 className="workshop-documentation-item-text-strong">Der Workshop</h3>
                                    <p>Wir führen den Workshop bei uns im Paixon-Büro, bei Ihnen, der Location Ihrer
                                        Wahl oder auch online durch.
                                    </p>
                                </div>
                            </div>
                            <div className="workshop-documentation-item workshop-documentation-item-last">
                                <div className="workshop-documentation-item-text">
                                    <h3 className="workshop-documentation-item-text-strong">Die Nachbearbeitung</h3>
                                    <p>Wir dokumentieren alle Erkenntnisse des Workshops, erweitern diese punktuell mit
                                        Expertenwissen und erstellen auf Wunsch ein Realisierungsangebot.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="workshop-question">
                        <h2>Wer soll am Workshop teilnehmen?</h2>
                        <p>Der Workshop ist geeignet für zwei bis sechs Teilnehmende:</p>
                        <ul>
                            <li>Produkt- oder Projektverantwortliche und Fachkundige</li>
                            <li>Prozess- und IT-Verantwortliche oder Mitarbeitende</li>
                            <li>Kundschaft oder Personen der internen Zielgruppen</li>
                        </ul>
                        <p>
                            Paixon stellt für jeden Workshop das geeignete Team mit Expertise in den Bereichen Chat- und
                            Voicebot, Marketing, Software-Entwicklung, Computerlinguistik und IT-Projektführung
                            zusammen.
                        </p>

                        {(workshopeBlog !== undefined && workshopeBlog.node.status === 'published') && (
                            <div className="workshopBlog">
                                <p>Im Blogbeitrag
                                    <a href="/blog/wir-bei-paixon/paixon-chatbot-workshop-erfahrungsbericht"
                                       title="Workshop Blog"> "Der Paixon Chatbot Workshop - Ein Erfahrungsbericht" </a>
                                    finden Sie einen Erfahrungsbericht zum Chatbot-Workshop, den wir in der Ostschweiz
                                    bei
                                    der
                                    SAK (St.Gallisch-Appenzellische Kraftwerke AG) durchführten.</p>
                            </div>
                        )}

                        <h2>Wie lange dauert der Workshop?</h2>
                        <p>Der Workshop dauert einen halben Tag (3-6 Stunden). Die Pausen werden der Dauer des Workshops
                            angepasst.</p>

                        <h2>Was kostet der Workshop?</h2>
                        <p>Im Preis von 2’400 CHF (exkl. MwSt) ist die Workshop-Durchführung, sowie die Vor- und
                            Nachbearbeitung inbegriffen. Sie erhalten von Paixon eine ausführliche
                            Workshop-Dokumentation und ein Umsetzungsangebot.</p>
                    </div>

                    <div className='section-white'>
                        <h3>Gemeinsam ins Zeitalter der digitalen Kundenansprache?</h3>
                        <div className="offer-call-to-action-button action-button-element">
                            <a className="action-button"
                               href="mailto:info@paixon.ch">
                                <h3 className="action-button-text">Kostenloses Gespräch vereinbaren</h3>
                            </a>
                        </div>
                    </div>

                </main>
            </Layout>
        )
    }
}


export const pageQuery = graphql`  
  query WorkshopPageQuery {
  allStrapiPageWorkshops {
    edges {
      node {
        slogan
        photo {
          publicURL
        }
      }
    }
  }
  allStrapiBlogs {
    edges {
      node {
        id
        status
      }
    }
  }
    allStrapiOfferPages {
        edges {
            node {
                title
                url
                order
            }
        }
    }
 }
`
